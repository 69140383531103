<template>
    <div>
        <Popup v-model="showPopup" v-if="changeShop" :close-on-click-overlay="false" position="bottom" get-container="body">
            <p style="font-size: 0.2rem; text-align: center; line-height: 0.4rem">更换原因</p>
            <RadioGroup v-model="reason">
                <CellGroup>
                    <Cell v-for='(item, index) in reasonList1' :title="item" clickable @click="reason = item" :key="index">
                        <template #right-icon>
                            <Radio :name="item" />
                        </template>
                    </Cell>
                </CellGroup>
            </RadioGroup>
            <div class="closeBtn">
                <van-button
                    size="normal"
                    type="default"
                    @click="
                        () => {
                            (showPopup = false), (reason = '');
                        }
                    "
                    >关闭</van-button
                >
                <van-button size="normal" @click="closeWebview(true)">确定</van-button>
            </div>
        </Popup>
        <Popup v-model="showPopup" v-else :close-on-click-overlay="false" position="bottom" get-container="body">
            <template v-if="orderDetail && orderDetail.itsItemData">
                <p style="font-size: 0.2rem; text-align: center; line-height: 0.4rem">用餐反馈</p>
                <RadioGroup v-model="reason" :disabled="'feedback_reason' in orderDetail.itsItemData ? orderDetail.itsItemData.feedback_reason !='' : false">
                    <CellGroup>
                        <Cell
                            v-for='(item, index) in reasonList2'
                            :title="item"
                            clickable
                            @click="
                                () => {
                                    orderDetail.itsItemData.feedback_reason ? '' : (reason = item);
                                }
                            "
                            :key="index"
                        >
                            <template #right-icon>
                                <Radio v-if="orderDetail.itsItemData.feedback_reason ? orderDetail.itsItemData.feedback_reason == item : true" :name="item" />
                            </template>
                        </Cell>
                    </CellGroup>
                </RadioGroup>
                <div class="closeBtn">
                    <van-button
                        size="normal"
                        type="default"
                        @click="
                            () => {
                                (showPopup = false), (reason = '');
                            }
                        "
                        >关闭</van-button
                    >
                    <van-button size="normal" v-if="!('feedback_reason' in orderDetail.itsItemData ? orderDetail.itsItemData.feedback_reason !='' : false)" type="info" @click="closeWebview(true)">确定</van-button>
                </div>
            </template>
        </Popup>
    </div>
</template>

<script>
import { Popup, RadioGroup, Radio, Cell, CellGroup } from "vant";
import { mapGetters } from "vuex";
export default {
    name: "sTimePiker",
    props: ["changeShop"],
    components: {
        Popup,
        RadioGroup,
        Radio,
        CellGroup,
        Cell,
    },
    computed: {
        ...mapGetters(["orderDetail"]),
    },
    data() {
        return {
            showPopup: false,
            reason: "",
            reasonList1: ['选错餐厅了/不想去了', '客户要求换餐厅', '餐厅不营业/餐厅搬迁', 'YES客服通知更换餐厅', '餐厅不适合邀请'],
            reasonList2: ["满意", "一般", "对餐厅（不营业、搬迁、服务等）不满意", "对系统（反应慢、付款不顺畅等）不满意", "其他"]
        };
    },
    methods: {
        async closeWebview() {
            if (!this.reason) {
                return;
            }
            this.$emit("closeWebview", this.reason);
        },
        show(flag) {
            this.showPopup = flag;
        },
    },
};
</script>

<style scoped lang="scss">
.closeBtn {
    display: flex;
    justify-content: space-around;
    padding: 0.2rem 0;
    button {
        width: 35%;
    }
}
</style>
