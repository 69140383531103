<template>
<div class="RoomInfoPage">
    <div class="RoomInfo">
        <div class="title">
            <div class="borderR">
                包房类型
            </div>
            <div>
                可容纳人数
            </div>
        </div>
        <div class="item" v-for="(item, index) in roomList" :key="index">
            <div class="borderR">
                {{item.name}}
            </div>
            <div>
                {{item.num}}
            </div>
        </div>
    </div>
    <div class="msg" @click="showMore" v-if="detailPage">
        查看更多包房信息
    </div>
</div>
</template>

<script>
export default {
    props: [
        'roomList',"detailPage"
    ],
    data(){
        return{
        }
    },
    mounted(){
    },
    methods:{
        showMore(){
            this.$emit('showMore')
        }
    }
}
</script>

<style scoped lang="scss">
.RoomInfoPage{
    padding: .05rem;
    box-sizing: border-box;
    width: 95vw;
    max-height: 80vh;
    overflow: scroll;
    color: #909399;
    .RoomInfo{
        width: 95%;
        margin: 0 auto;
        border: 1px solid #efefef;
        border-radius: .05rem;
        .title{
            display: flex;
            width: 100%;
            height: .3rem;
            background-color: #f5f5f5;
            border-bottom: 1px solid #efefef;
            color: #323233;
            div{
                width: 50%;
                text-align: center;
                line-height: .3rem;
            }
        }
        .item{
            display: flex;
            width: 100%;
            height: .3rem;
            div{
                width: 50%;
                text-align: center;
                line-height: .3rem;
            }
        }
        .item+.item{
            border-top: 1px solid #efefef;
        }
        .borderR{
            border-right: 1px solid #efefef;
        }
    }
    .msg{
        text-align: center;
        font-size: .14rem;
        line-height: .3rem;
        color: #ccc;
    }
}
</style>