<template>
    <Popup v-model="isShow" v-if="isShow" position="bottom" get-container="body" :lazy-render="true">
        <div class="itemContent">
            <div
                class="item" 
                v-for="(item, index) in itemList"
                :key="index"
                @click="handlechannel(item)"
            >   
                <div class="icon-content icon-content-nonal" >
                    <Svgs :name="item.icon || ''"></Svgs>
                </div>
                <div class="subTitle">{{item.txt}}</div>
            </div>
        </div>
    </Popup>
</template>

<script>
import { Popup } from 'vant';
export default {
    components:{Popup},
    name: "changeShopPop",
    props:[
        'itemList'
    ],
    data() {
        return {
            isShow: false
        };
    },
    created() {

    },
    methods: {
        // plugin入口
        open() {
            this.isShow = true;
        },
        handlechannel(item){
            this.$emit("handlechannel", item);
            this.isShow = false
        }
    },
};
</script>

<style lang="scss" scoped>
.itemContent {
    padding: 0.15rem 0.15rem 0 0.15rem;
    display: flex;
    border-bottom: 3px solid #f1f1f1;
    .item {
        width: 0.56rem;
        margin-right: 0.22rem;

        .icon-content {
            width: 0.56rem;
            height: 0.56rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0.1rem;

            .icon {
                width: 0.44rem;
                height: 0.44rem;
            }
            
        }
        .icon-content-nonal {
            background: #f8f8f8;
        }

        .subTitle {
            color: #72717d;
            font-size: 0.12rem;
            // height: 0.4rem;
            // line-height: 0.4rem;
            margin: 0.1rem 0;
        }
        .icon-DianPing {
            background: rgb(7, 196, 189);
        }
        .icon-MeiCan {
            background: rgb(108, 187, 26);
        }
        .icon-ELE {
            background: rgb(42, 150, 242);
        }
    }
}
</style>
